@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "Codec Pro Bold";
  src: url("../fonts/Codec\ Pro\ Bold.ttf");
}
@font-face {
  font-family: "Codec Pro Extrabold";
  src: url("../fonts/Codec\ Pro\ Extrabold.ttf");
}
@font-face {
  font-family: "General Sans Semibold";
  src: url("../fonts/GeneralSans-Semibold.otf");
}
@font-face {
  font-family: "General Sans Medium";
  src: url("../fonts/GeneralSans-Medium.otf");
}
@font-face {
  font-family: "General Sans bold";
  src: url("../fonts/GeneralSans-Bold.otf");
}
@font-face {
  font-family: "General Sans";
  src: url("../fonts/GeneralSans-Regular.otf");
}
@font-face {
  font-family: "Codec Pro Heavy";
  src: url("../fonts/Codec\ Pro\ Heavy.ttf");
}

.inter-font {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

$header-color: linear-gradient(127.55deg, #141e30 3.73%, #243b55 92.26%);
$theme-color: linear-gradient(
  90deg,
  #ed1f23 0.12%,
  #ed1f23 8.11%,
  #ed1f23 15.51%,
  #ed2023 22.41%,
  #ee2023 28.89%,
  #ee2123 35.05%,
  #ef2223 40.99%,
  #ef2323 46.78%,
  #f02423 52.53%,
  #f12523 58.33%,
  #f12723 64.27%,
  #f22823 70.43%,
  #f32a23 76.91%,
  #f42c23 83.81%,
  #f52d23 91.21%,
  #f62f23 99.2%
);
$text-color: #1a1616;
$theme-color-hex: #ed1f23;
$theme-matching: #ffebeb;
$theme-border: #504d4d;

.font-gs-b {
  font-family: General Sans bold;
  font-weight: 700;
}
.font-gs-sb {
  font-family: General Sans Semibold;
  font-weight: 600;
}
.font-gs-md {
  font-family: General Sans Medium;
  font-weight: 500;
}
// .scrollbar-hide::-webkit-scrollbar {
//   display: none;
// }

$title-font-size: 2.438rem;
$subHead-font-size: 1.25rem;
$normal-font-size: 1rem;

$primary-font: Poppins, sans-serif;
$codec-bold: Codec Pro Bold;
$para-font: "General Sans";
$secondary-font: Roboto, sans-serif;

@mixin generalSans($weight) {
  @if ($weight == 600) {
    font-family: General Sans Semibold !important;
  } @else if($weight == 500) {
    font-family: "General Sans Medium";
  } @else if($weight == 700) {
    font-family: "General Sans Bold";
  } @else if ($weight == 400) {
    font-family: "General Sans";
  }
}
@mixin centered($allignment) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $allignment;
}
@mixin containedBtn() {
  background: $theme-color !important;
  border-radius: 64px;
  padding: 1rem;
  @include generalSans(500);
  font-size: 1.563rem;
}

@mixin title() {
  font-size: $title-font-size;
  font-family: $codec-bold;
}

@mixin subHead() {
  font-size: $subHead-font-size;
  @include generalSans(600);
}

@mixin description() {
  font-size: $normal-font-size;
  @include generalSans(500);
}

// Loading Spinner
.spinner {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #000000 94%, #0000) top/2.9px 2.9px
      no-repeat,
    conic-gradient(#0000 30%, #000000);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 2.9px),
    #000 0
  );
  animation: spinner-c7wet2 1s infinite linear;
  margin-left: 0.5rem;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}

.additional-info-note {
  font-size: 14px;
  @include generalSans(400);
  color: #8d8b8b;
  padding-left: 1rem;
  padding-top: 0.7rem;
}
.MuiStepLabel-label {
  @include generalSans(600);
  font-size: 14.5px;
  color: $text-color !important;
  margin-top: 10px !important;
}

.MuiStepLabel-label.Mui-disabled {
  font-family: "General Sans" !important;
  font-size: 13px;
  color: $text-color !important;
  margin-top: 10px !important;
}

.navbar {
  background-color: $header-color;
  .nav-link {
    text-decoration: none;
    color: $text-color;
    margin: 0 1.5rem;
    cursor: pointer;
    @include generalSans(500);
    &.active {
      position: relative;
    }
    &.active::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -7px;
      width: 100%;
      height: 5px;
      background-color: $theme-color-hex;
      border-radius: 5rem;
    }
  }
}
.headerBox {
  .btn-outlined-secondary {
    color: $text-color !important;
    @include generalSans(600);
    font-size: $normal-font-size;
    border: 1px solid $theme-border;
    border-radius: 6px;
    background-color: #fff;
    padding: 0.7rem 1.3rem;
    &:hover {
      text-decoration: none;
    }
  }
  .page-text {
    color: $text-color;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    &:nth-child(1) {
      font-weight: 700;
    }
  }
  .expand-icon {
    color: $text-color;
  }
}
.dropdown-box {
  min-width: 200px;
  background: $theme-color;
  border-radius: 0%;
  padding: 0%;
  // sx={{ minWidth: 200,backgroundColor: '#151F31',borderRadius:'0%', p:0 }}
}

.sidebar {
  border-top: 1px solid $theme-border;
  .sidebar-text {
    color: $text-color;
    text-align: left;
    &.title {
      @include title();
    }
    &.sub-head {
      @include subHead();
    }
    &.description {
      @include description();
    }
  }
}

.homepage {
  .page-head {
    color: $text-color;
    @include title();
  }
  .page-subhead {
    font-family: $para-font;
    font-size: 2rem;
  }
  .btn {
    @include containedBtn();
    font-size: 16px;
    padding: 0.7rem 2rem;
    .btn-icon {
      font-size: 2.5rem;
    }
  }
}
.dropdown-popper {
  border: 1px solid $text-color !important;
  border-radius: 8px;
  padding: 0 2rem;
  a {
    text-decoration: none !important;
    cursor: pointer;
    .dropdown-item {
      display: flex;
      min-width: 200px;
      padding: 1rem 0;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #d0cfcf;
      img {
        width: 29.17px;
      }
      .dropdown-text {
        color: $text-color;
        font-size: $normal-font-size;
        @include generalSans(600);
        margin-bottom: 0%;
        margin-left: 2px;
      }
    }
    &:last-child {
      .dropdown-item {
        border-bottom: none;
      }
    }
  }
}
.dropdown-settings-popper {
  border: 1px solid $text-color !important;
  border-radius: 0px;
  padding: 0;
  width: 210px;
  background-color: #fff;

  .list {
    padding: 0.5rem 0;
    display: flex;
    min-width: 100px;
    border-radius: 0%;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(26, 22, 22, 0.1);
    &:last-child {
      border-bottom: 0px solid red;
    }
    .list-item {
      .MuiListItemText-primary {
        color: $text-color;
        font-size: $normal-font-size;
        @include generalSans(600);
        margin-bottom: 0%;
        margin-left: 2px;
      }
    }
  }
}
.list-popper {
  border: 1px solid $text-color !important;
  border-radius: 8px;
  padding: 0 1rem;
  max-width: 250px;
  .MuiBox-root {
    background-color: white;
  }
  .list {
    border-bottom: 1px solid #d0cfcf;
    padding: 0.5rem 0;
    display: flex;
    min-width: 200px;
    border-radius: 0%;
    align-items: center;
    justify-content: flex-start;
    &:last-child {
      border-bottom: none !important;
    }
    .list-item {
      .MuiListItemText-primary {
        color: $text-color;
        font-size: $normal-font-size;
        @include generalSans(600);
        margin-left: 2px;
        margin-bottom: 0%;
      }
    }
  }
}

.registration-page {
  .title {
    @include title();
    color: #1a1616;
  }
  .registration-form {
    border: 1px solid $theme-border;
    border-radius: 6px;
  }
  .terms-conditions {
    @include generalSans(500);
    color: #223354 !important;
  }
  input.MuiOutlinedInput-input::placeholder {
    color: #8d8b8b;
  }
  input.MuiOutlinedInput-input {
    color: #8d8b8b;
    opacity: 1 !important;
  }

  input.MuiOutlinedInput-input::placeholder {
    opacity: 1 !important;
  }
  .create-btn {
    @include generalSans(600);
  }
}

.almost-done-page {
  .title {
    @include title();
    color: $text-color;
  }
  .para-text {
    font-family: $para-font;
    font-size: 2rem;
  }
  .resend-btn {
    @include generalSans(500);
  }
}

.rewards-section {
  // .nav-tabs-button{
  //     box-shadow: none !important;
  //     outline: none !important;
  //     background: #fff;
  //     border: 1px solid $theme-color-hex;
  // }
  .title {
    @include title();
    color: $text-color;
  }
  .subtitle {
    font-size: 16px;
    @include generalSans(500);
    color: #8d8b8b;
  }
  .btn {
    border-radius: 6px;
    padding: 0.6rem 2rem;
    @include generalSans(500);
    font-size: $normal-font-size;
    // sx={{borderRadius: '6px', padding: '0.6rem 2rem'}}
  }
  .no-active-drops {
    @include centered(column);
    height: 425px;
    img {
      object-fit: contain;
    }
    .helper-text {
      color: $text-color;
      @include generalSans(500);
      text-align: center;
      font-size: 1.563rem;
    }
    a {
      color: $theme-color-hex;
      font-size: 1.563rem;
      @include generalSans(500);
      text-decoration: none;
      text-align: center;
      display: block;
    }
  }
}

.collections {
  .title {
    @include title();
    color: $text-color;
  }
  .btn {
    border-radius: 6px;
    padding: 0.6rem 2rem;
    @include generalSans(500);
    font-size: $normal-font-size;
    // sx={{borderRadius: '6px', padding: '0.6rem 2rem'}}
  }
  .no-collections {
    @include centered(column);
    height: 425px;
    .helper-text {
      color: $text-color;
      @include generalSans(500);
      text-align: center;
      font-size: 1.563rem;
    }
    a {
      color: $theme-color-hex;
      font-size: 1.563rem;
      @include generalSans(500);
      text-decoration: none;
      text-align: center;
      display: block;
    }
  }
  .table-card {
    max-width: 988px;
    border-radius: 6px;
    border: 1px solid $theme-border;
    padding: 1rem 1.5rem;
    box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
      0px 2px 2px rgba(159, 162, 191, 0.32);
    .table {
      border: 1px solid $theme-border;
      .table-head {
        background-color: #f3f3f3;
        border-bottom: 1px solid $theme-border;
        @include generalSans(600);
        color: $theme-border;
        font-size: 13px;
        &:nth-child(1) {
          padding-left: 2.8rem;
        }
      }
      .collection-row {
        background-color: rgba(235, 246, 255, 0.3);
        th {
          &:nth-child(1) {
            padding-left: 2.8rem;
          }
        }
        td,
        th {
          border-bottom: 1px solid $theme-border;
        }
        .collection-image {
          height: 70px !important;
          width: 70px !important;
          object-fit: cover;
          border-radius: 6px !important;
        }
        .collection-name {
          @include generalSans(700);
          color: #343030;
          font-size: $normal-font-size;
        }
        .collection-size {
          @include generalSans(700);
          font-size: $normal-font-size;
          color: $text-color;
        }
        .collection-description {
          font-family: $para-font;
          font-size: 13px;
          color: $text-color;
        }
        .action-icon {
          color: $text-color;
          font-weight: bold;
          padding: 0px;
          justify-content: flex-start;
        }
      }
      // sx={{ minWidth: 650, border: 0, boxShadow: 0, borderRadius: 0, p: 5 }}
    }
  }
}

.sidebar-drop-box {
  @include centered(column);
  .heading {
    @include generalSans(600);
    font-size: 1.563rem;
    color: $text-color;
    text-align: center;
  }
}

.multistep-form {
  border: 1px solid #504d4d;
  border-radius: 6px;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  .subtitle {
    font-size: 16px;
    @include generalSans(400);
    color: #8d8b8b;
  }
  .step-1 {
    .title {
      color: $text-color;
      font-size: 2rem;
      @include generalSans(700);
      text-align: center;
    }
    .subtitle {
      font-size: 16px;
      @include generalSans(400);
      color: #8d8b8b;
    }
    .btn-outlined {
      padding: 0.4rem 1.8rem;
      @include generalSans(600);
    }
    .drop-type-card {
      cursor: pointer;
      position: relative;
      height: 150px;
      width: 150px;
      border: 1px solid $theme-border;
      &.selected {
        border: 3px solid $theme-color-hex;
      }
      .card-content {
        border-top: 1px solid $theme-border;
      }
      .drop-title {
        color: #000;
        @include generalSans(600);
        letter-spacing: 0.02em;
        font-size: 13px;
        word-break: break-all;
      }
      .drop-description {
        color: #a1a1a1;
        @include generalSans(500);
        letter-spacing: 0.02em;
        font-size: 10px;
        word-break: break-word;
        line-height: 120%;
      }
    }
    .table {
      // className='table' sx={{ minWidth: 650, minHeight: '10rem', boxShadow: 0, borderRadius: 0, p: 5 }}
      max-width: 600px;
      min-height: 10rem;
      border: 1px solid $theme-border;
      .table-head {
        background-color: #f3f3f3;
        border-bottom: 1px solid $theme-border;
        @include generalSans(600);
        color: $theme-border;
        font-size: 13px;
        &:nth-child(1) {
          padding-left: 3rem;
        }
      }
      .collection-table {
        .MuiTableCell-root {
          border-top: 1px solid $theme-border;
          background: rgba(235, 246, 255, 0.3);
        }
        .collection-image {
          height: 70px;
          width: 70px;
          border-radius: 6px;
          margin-right: 10px;
        }
        .collection-name {
          @include generalSans(700);
          color: #343030;
          font-size: $normal-font-size;
        }
        .collection-size {
          @include generalSans(700);
          font-size: $normal-font-size;
          color: $text-color;
        }
      }
    }
  }
  .step-2 {
    .title {
      color: $text-color;
      font-size: 2rem;
      @include generalSans(700);
      text-align: center;
    }
  }
  .step-3 {
    .title {
      color: $text-color;
      font-size: 2rem;
      @include generalSans(700);
      text-align: center;
    }
  }
}
.preview-dropbox {
  width: 326px;
  height: 517px;
  border: 1px solid $theme-border;
  border-radius: 6px;
  position: relative;
  .card-header {
    height: 18px;
  }
  .card-content {
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .drop-category {
    @include generalSans(600);
    font-size: 13px;
    letter-spacing: 0.02em;
    color: $text-color;
    padding-left: 5px;
  }
  .image-com {
    border-top: 1px solid $theme-border;
    border-bottom: 1px solid $theme-border;
  }
  .no-image {
    background-color: $theme-matching;
    border-top: 1px solid $theme-border;
    border-bottom: 1px solid $theme-border;
  }
  .MuiSkeleton-root {
    border-radius: 0%;
  }
  .drop-title {
    @include generalSans(600);
    font-size: $normal-font-size;
    color: #223354;
  }
  .drop-description {
    @include generalSans(500);
    font-size: 13px;
    color: #aeacac;
    word-break: break-word;
  }
  .ends-text {
    @include generalSans(500);
    font-size: 10px;
    text-transform: uppercase;
    color: #aeacac;
    text-align: right;
  }
  .ends-time {
    @include generalSans(600);
    font-size: $normal-font-size;
    color: $text-color;
    text-align: right;
  }
  .claimed-count {
    font-family: $secondary-font;
    text-align: right;
    .claimed {
      @include generalSans(500);
      font-size: 10px;
      color: #aeacac;
      text-transform: uppercase;
    }
    .claimed-quantity {
      @include generalSans(600);
      color: $text-color;
      font-size: $normal-font-size;
      line-height: 16px;
    }
    .claimed-limit {
      @include generalSans(500);
      font-size: 10px;
      color: #8d8b8b;
    }
  }
  //sx={{cursor:"pointer",height:'40px', width: '40px', borderRadius:'50%', marginLeft:'-1rem', background: '#fff !important',zIndex:10,color:'#1A75FF', boxShadow: '0 3px 3px #ccc'}}
  .sponsor-border {
    border: 2px solid #fff;
  }
  .more-sponsors-box {
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-color: #fff !important;
    z-index: 10;
    margin-left: -1rem;
    border: 2px solid #223354;
    border-radius: 50%;
    font-family: $secondary-font;
    font-weight: 700;
    color: #223354;
  }
  .sponsors-area {
    height: 45px;
    .sponsor-name {
      @include generalSans(600);
      font-size: 13px;
      padding-left: 10px;
      color: #000;
    }
  }
}
.dropbox {
  width: 328px;
  border: 1px solid $theme-border;
  position: relative;
  height: 517px;
  width: 326px;
  margin-bottom: 0.8rem;
  border-radius: 6px;
  .card-header {
    height: 18px;
  }
  .sponsor-border {
    border: 2px solid #fff;
  }
  .card-content {
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .more-sponsors-box {
    cursor: pointer;
    height: 32px !important;
    width: 32px !important;
    background-color: #fff !important;
    z-index: 10;
    margin-left: -1rem;
    border: 2px solid #223354;
    border-radius: 50%;
    font-family: $secondary-font;
    font-weight: 700;
    color: #223354;
    font-size: 12px;
  }
  .drop-category {
    @include generalSans(600);
    font-size: 13px;
    letter-spacing: 0.02em;
    color: $text-color;
    padding-left: 5px;
  }
  .image-com {
    border-top: 1px solid $theme-border;
    border-bottom: 1px solid $theme-border;
  }
  .no-image {
    background-color: $theme-matching;
    border-top: 1px solid $theme-border;
    border-bottom: 1px solid $theme-border;
  }
  .MuiSkeleton-root {
    border-radius: 0%;
  }
  .drop-title {
    @include generalSans(600);
    font-size: $normal-font-size;
    color: #223354;
  }
  .drop-description {
    @include generalSans(500);
    font-size: 13px;
    color: #aeacac;
    word-break: break-word;
  }
  .ends-text {
    @include generalSans(500);
    font-size: 10px;
    text-transform: uppercase;
    color: #aeacac;
    text-align: right;
  }
  .ends-time {
    @include generalSans(600);
    font-size: $normal-font-size;
    color: $text-color;
    text-align: right;
  }
  .started-text {
    @include generalSans(500);
    font-size: 10px;
    text-transform: uppercase;
    color: #aeacac;
  }
  .started-time {
    @include generalSans(600);
    font-size: $normal-font-size;
    color: $text-color;
  }
  .claimed-count {
    text-align: right;
    .claimed {
      @include generalSans(500);
      font-size: 10px;
      color: #aeacac;
      text-transform: uppercase;
    }
    .claimed-quantity {
      @include generalSans(600);
      color: $text-color;
      font-size: $normal-font-size;
      line-height: 16px;
    }
    .claimed-limit {
      @include generalSans(500);
      font-size: 10px;
      color: #8d8b8b;
    }
  }
  .sponsors-area {
    .sponsor-name {
      @include generalSans(600);
      font-size: 13px;
      padding-left: 10px;
      color: #000;
    }
  }
}
.sponsorcol_image {
  border: 1.13514px solid #ffffff;
}
.sponsor_title {
  font-family: $secondary-font;
  font-weight: 700;
  padding-left: 5px;
}

.settings {
  .MuiTypography-h3 {
    @include generalSans(700);
    font-size: 20px;
    color: #000000;
  }
  .MuiTypography-h6 {
    @include generalSans(500);
    font-size: 16px;
    color: #8d8b8b;
  }
  .title {
    @include title;
  }
  .btn-text {
    @include generalSans(600);
  }
  .subhead {
    font-family: $para-font;
    margin-top: 0px;
    color: #1a1616;
    opacity: 0.7;
  }
  .account-settings {
    .account-card {
      border: 1px solid $theme-border;
      border-radius: 6px;
    }
    .resend-btn {
      color: $text-color;
      @include generalSans(600);
      text-decoration: underline;
    }
  }
  .claim-settings {
    .claim-heading {
      @include generalSans(600);
      font-size: 25px;
      color: #000;
    }
    .claim-card {
      border: 1px solid $theme-border;
      border-radius: 6px;
    }
    .hostname {
      @include generalSans(600);
      font-size: $normal-font-size;
    }
  }
  .advanced-settings {
    .MuiLink-root {
      text-decoration: underline;
    }
    .MuiTypography-h3 {
      @include generalSans(700);
      font-size: 20px;
      color: #000000;
    }
    .MuiTypography-h5 {
      @include generalSans(500);
      font-size: 16px;
      color: #8d8b8b;
      font-weight: normal;
    }
    .MuiTypography-h4 {
      @include generalSans(500);
      font-size: 20px;
      color: #000000;
    }
    .MuiTypography-h5 {
      @include generalSans(500);
      font-size: 16px;
      color: #8d8b8b;
    }
    .MuiTypography-body1 {
      @include generalSans(500);
      color: #8d8b8b;
      font-size: 13px;
      line-height: 18px;
    }
  }
  .domain-settings {
    .MuiLink-root {
      text-decoration: underline;
    }
    .MuiTypography-h3 {
      @include generalSans(700);
      font-size: 20px;
      color: #000000;
    }
    .MuiTypography-h4 {
      @include generalSans(500);
      font-size: 20px;
      color: #000000;
    }
    .MuiTypography-h5 {
      @include generalSans(500);
      font-size: 16px;
      color: #000000;
    }
    .MuiTypography-h6 {
      @include generalSans(500);
      font-size: 16px;
      color: #8d8b8b;
    }
    .MuiTypography-body1 {
      @include generalSans(500);
      color: #8d8b8b;
      font-size: 13px;
      line-height: 18px;
    }
    .MuiRadio-colorPrimary:not(.Mui-checked) + .MuiFormControlLabel-label {
      opacity: 0.5;
    }
  }
  .design-settings {
    .MuiTypography-h4 {
      @include generalSans(600);
      font-size: 16px;
      color: #000000;
    }
    .MuiTypography-h5 {
      @include generalSans(500);
      font-size: 16px;
      color: #000000;
    }
  }
  .plan-settings {
    .heading {
      @include generalSans(700);
    }
    .subhead {
      font-family: $para-font;
      padding-top: 0.7rem;
    }
  }
}

// Modals

.modals {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  .MuiDialog-container {
    .MuiPaper-root {
      border-radius: 6px !important;
      box-shadow: none;
    }
  }
  .modal-card {
    border: 1px solid $theme-border;
    border-radius: 6px;
    box-shadow: none;
    .coupon-card {
      // sx={{bgcolor:'rgba(34, 51, 84, 0.02)', mt:"10px" , p:2 , boxShadow:'none'}}
      background-color: rgba(34, 51, 84, 0.02);
      margin-top: 10px;
      box-shadow: none;
    }
  }
  .heading {
    text-align: center;
    @include generalSans(700);
    font-size: 2rem;
    color: #1a1616;
  }
  .helper-text {
    @include generalSans(500);
    font-size: $normal-font-size;
    color: rgba(34, 51, 84, 0.5);
    text-align: center;
    padding-top: 0.7rem;
  }
  .title {
    @include title();
    font-size: 2rem;
  }
  .btn-text {
    font-family: $secondary-font;
    font-weight: 700;
  }
}

.preview-modal {
  .modal-border {
    border: 1px solid $theme-border;
  }
  .preview-title {
    font-family: $primary-font;
    font-weight: 600;
    font-size: 1.5rem;
    color: #1a1616;
    position: absolute;
    top: -4rem;
    left: 18px;
  }
  .question {
    font-family: $primary-font;
    font-weight: 400;
    font-size: 1.5rem;
  }
  .question-helper {
    font-family: "Inter", sans-serif;
    color: #343957;
    font-size: 14px;
  }
  .option-area {
    padding: 1rem;
    word-break: break-all;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #000;
    font-weight: 600;
    background: rgba(26, 117, 255, 0.1);
    border: 1px solid #1a1616;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
    cursor: pointer;
    &.selected {
      background: rgba(26, 117, 255, 0.4);
    }
  }
  .confirm-btn {
    margin-top: 3rem;
    width: 100%;
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 15px;
  }
  .allow-list-card {
    background: #f6f8fb;
    border: 1px solid #cccedd;
    box-shadow: 0px 4px 21px rgba(24, 69, 136, 0.2);
    border-radius: 6px;
    .inner-al-card {
      background: rgba(34, 51, 84, 0.02);
      border-radius: 8px;
      box-shadow: none;
      .detail-card {
        background-color: #fff;
        box-shadow: none;
        border-radius: 0%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
          border-radius: 6px;
          padding: 5px 10px;
          font-family: $secondary-font;
          font-weight: 700;
          font-size: 14px;
        }
        .helper-title {
          color: #223354;
          font-family: $primary-font;
          font-weight: 700;
          font-size: 14px;
        }
        .helper-description {
          color: rgba(34, 51, 84, 0.5);
          font-family: "Inter", sans-serif;
          font-size: 10px;
          font-weight: 700;
        }
      }
    }
  }
  .modal-title {
    font-family: $primary-font;
    font-weight: 700;
    text-align: center;
    font-size: $normal-font-size;
    color: #0c8bf4;
    word-break: break-all;
  }
  .heading {
    font-family: $primary-font;
    font-weight: 700;
    text-align: center;
    font-size: 2rem;
    color: #223354;
    word-break: break-all;
  }
  .description {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: $normal-font-size;
    color: #343957;
    text-align: center;
    margin: 1rem 0;
  }
  .merch-category {
    @include generalSans(600);
    font-size: 13px;
    color: #1a1616;
    margin-left: 5px;
  }
  .coupon-code {
    font-family: Space Mono;
    font-weight: 400;
    font-style: italic;
    color: #223354;
  }
  .go-to-site-btn {
    background-color: #0c8bf4;
    border-radius: 6px;
    width: 100%;
    color: #fff;
    font-family: $secondary-font;
    font-weight: 700;
    font-size: $normal-font-size;
    margin: 10px 0;
  }
  .close-btn {
    color: $text-color;
    @include generalSans(700);
    font-size: $normal-font-size;
    border: 1px solid $theme-border;
    padding: 0.6rem 2rem;
  }
  .content-name {
    @include generalSans(600);
    margin-left: 10px;
  }
  .btn-text {
    font-family: $secondary-font;
    font-weight: 700;
  }
}

.Mui-focused.MuiFormLabel-root {
  color: #504d4d;
}

.signup-successfull {
  .title {
    text-align: center;
    @include title();
    color: $text-color;
  }
  .description {
    text-align: center;
    font-size: 1.563rem;
    font-family: $para-font;
    font-weight: 400;
  }
  .connect-title {
    // sx={{mt:2,color: '#223354', fontSize:'2.5rem', fontFamily:'Roboto, sans-serif'}} align="center"
    @include generalSans(600);
    text-align: center;
    font-size: 2.5rem;
    color: $text-color;
  }
  .login-desc {
    text-align: center;
    font-family: $para-font;
    font-size: 1.5rem;
  }
  .btn {
    @include containedBtn();
    font-size: 16px;
    padding: 0.7rem 2rem;
    .btn-icon {
      font-size: 2.5rem;
    }
  }
}

.claim-modal {
  .modal-card {
    box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
      0px 2px 2px rgba(159, 162, 191, 0.32);
    border: 1px solid $theme-border;
  }
  .claim-card {
    .title {
      @include generalSans(700);
      font-size: 18.646px;
      color: $text-color;
    }
    .description {
      @include generalSans(500);
      font-size: 9.696px;
      color: #8d8b8b;
      padding: 0 5rem;
    }
  }
  .merch-category {
    @include generalSans(600);
    font-size: 9.696px;
    color: #330a0a;
    padding-left: 5px;
  }
  .reward-title {
    @include generalSans(700);
    color: #223354;
    font-size: 18.646px;
  }
  .reward-description {
    @include generalSans(500);
    font-size: 9.696px;
    color: #8d8b8b;
  }
  .reward-card {
    border-radius: 6px;
    border: none;
    box-shadow: none;
  }
  .left-side {
    .total-claim {
      font-family: $primary-font;
      font-weight: 800;
      font-size: 2rem;
      color: #ebf9ff;
      font-style: italic;
    }
    .quantity {
      font-family: $primary-font;
      font-weight: 600;
      font-size: $normal-font-size;
      color: rgba(235, 249, 255, 0.7);
      font-style: italic;
    }
    .claimed-text {
      font-family: $primary-font;
      font-weight: 400;
      font-style: italic;
      color: rgba(235, 249, 255, 0.55);
      font-size: 14px;
    }
  }
  .detail-card {
    background-color: #f3f3f3;
    box-shadow: none;
    border-radius: 8px;
    .started-text {
      font-family: Inter, sans-serif;
      font-weight: 400;
      color: rgba(34, 51, 84, 0.5);
      font-size: 8.95px;
    }
    .started-time {
      font-family: $primary-font;
      font-weight: 700;
      font-size: 11.934px;
      color: #223354;
    }
    .sponsor-name {
      font-family: $primary-font;
      font-weight: 700;
      font-size: 8.95px;
      color: #000;
    }
    .btn {
      width: 100%;
      @include generalSans(600);
      font-size: 11.934px;
    }
  }
}

.poll-form {
  .title {
    @include generalSans(700);
    color: #1a1616;
    font-size: 2rem;
  }
  .label-text {
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 1rem;
    color: #000;
    letter-spacing: 0.09px;
  }
  .delete-choice-btn {
    background: #fff7eb;
    border-radius: 6px;
    height: 40px;
    min-width: 40px !important;
  }
}

.gated-content-form {
  .title {
    @include generalSans(700);
    color: #1a1616;
    font-size: 2rem;
  }
}

.event-pass-form {
  .title {
    @include generalSans(700);
    color: #1a1616;
    font-size: 2rem;
  }
  .option_description {
    @include generalSans(500);
    color: #8d8b8b;
  }
}

.MuiStepLabel-label,
.MuiStepLabel-label.Mui-active {
  @include generalSans(500);
}
.font-weight-600 {
  @include generalSans(600);
}

.publishing_options {
  .MuiSwitch-thumb {
    background-color: #0a2033;
  }
  .Mui-checked .MuiSwitch-thumb {
    background: #f3f3f3;
    border: 1px solid #d0cfcf;
    box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
      0px 2px 2px rgba(159, 162, 191, 0.32);
  }
  .MuiSwitch-track {
    background: #cccedd;
    opacity: 0.5;
    box-shadow: inset 0px 1px 1px rgba(119, 126, 165, 0.25);
    border-radius: 6px;
  }
  .Mui-checked + .MuiSwitch-track,
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: #cccedd;
    opacity: 0.5;
    box-shadow: inset 0px 1px 1px rgba(119, 126, 165, 0.25);
    border-radius: 6px;
  }
  .MuiSwitch-switchBase.Mui-checked:hover {
    background-color: transparent;
  }
  .option_card {
    background-color: rgba($color: #f3f3f3, $alpha: 0.5);
    border-radius: 10px;
    .option_title {
      @include generalSans(700);
      font-size: 1.25rem;
      color: $text-color;
    }
    .option_description {
      @include generalSans(500);
      color: #8d8b8b;
      font-size: 13px;
    }
  }
}
.MuiChip-filled {
  background-color: #ebf6ff;
}
.MuiInputLabel-root,
fieldset.MuiOutlinedInput-notchedOutline legend,
fieldset.MuiOutlinedInput-notchedOutline legend span {
  text-transform: uppercase;
}

/* .MuiStepper-root {
    background-color: rgba(26, 22, 22, 0.02);
} */

.MuiStepper-root {
  // background-image: url("/assets/img/stepper-line.png");
  background-repeat: repeat-x;
  background-position: center 35px;
}
.MuiStepConnector-line {
  border-top-width: 0px !important;
}

.MuiStepLabel-root {
  .MuiStepLabel-iconContainer {
    .MuiSvgIcon-root {
      border-radius: 50%;
      border: 5px solid #ffffff;
    }
  }
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary:not(.Mui-selected) {
  color: rgba(26, 22, 22, 0.8);
}

.claim-blue-btn {
  background: linear-gradient(
    269.93deg,
    #2a94ec -1.34%,
    #2994ec 6.83%,
    #2993ec 14.4%,
    #2893ec 21.45%,
    #2792ed 28.07%,
    #2690ed 34.37%,
    #248fed 40.44%,
    #228dee 46.37%,
    #208bee 52.24%,
    #1e88ef 58.17%,
    #1b86f0 64.23%,
    #1983f1 70.53%,
    #1680f1 77.16%,
    #137df2 84.21%,
    #0f7af3 91.77%,
    #0c77f4 99.95%
  ) !important;
  width: 100%;
  @include generalSans(600);
  font-size: $normal-font-size;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  line-height: 1.75em !important;
}

.detail-box {
  @include generalSans(400);
  font-size: 16px;
  color: #9099a9;
  font-size: 500;
}

.empty-helper-txt {
  @include generalSans(500);
  font-size: 25px;
  color: #1a1616;
  text-align: center;
}

.raffle-chip-label {
  @include generalSans(600);
  color: #1a1616;
  background-color: #ebfff9 !important;
}
.raffle-message {
  font-family: Inter, sans-serif;
  color: #343957;
  font-weight: 400;
  font-weight: 14px;
  line-height: 150%;
  text-align: center;
}
.raffle-end-timer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135.7%;
}
.raffle-end-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135.7%;
  color: #223354;
}
.draw-winner-label {
  background-color: #f3f3f3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 90px;
  height: 65px;
  .MuiTypography-body1 {
    @include generalSans(500);
    color: #000000;
    font-size: 13px;
    line-height: 18px;
  }
  .MuiTypography-h3 {
    @include generalSans(700);
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
  }
}

.draw-raffle-label-msg {
  @include generalSans(600);
  color: #000000;
  font-size: 16px;
  line-height: 22px;
}

.delete-action-icon {
  color: $text-color;
  font-weight: bold;
  padding: 0px;
  justify-content: flex-start;
}

.raffle-th {
  @include generalSans(600);
}
.draw-raffle-note-msg {
  font-size: 13px;
  color: #1a1616;
}

.draw-raffle-note-msg-2 {
  font-size: 13px;
  color: #8d8b8b;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
  border-left: 1px solid black;
}
.MuiTableRow-root .MuiTableCell-root:last-child {
  border-right: 1px solid black;
}
.draw-raffle-email-label {
  @include generalSans(400);
  font-size: 13px;
  color: #1a1616;
}
.draw-raffle-email-label strong {
  @include generalSans(600);
  color: #1a1616;
  color: #1a1616;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.coming-soon-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.react-datepicker__input-container > input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.loginModal {
  .title {
    @include generalSans(700);
    font-size: 32px;
    color: #1a1616;
    margin-top: 45px;
    margin-bottom: 77px;
  }
  .ortext {
    @include generalSans(500);
  }
  .box-title {
    @include generalSans(500);
    font-size: 22px;
    color: #1a1616;
    text-align: center;
  }
  .btn {
    border-radius: 6px;
    padding: 0.6rem 2rem;
    @include generalSans(500);
    font-size: $normal-font-size;
    color: white;
  }
}
.MuiInputBase-input {
  padding: 10px 14px;
}
.Add-image {
  .MuiInputBase-input {
    padding: 16.5px 14px 16.5px 0;
  }
  #image_preview {
    display: flex;
    align-items: center;
  }
}

.textareArea {
  textarea.MuiInputBase-input {
    padding: 0px !important;
  }
}

.MuiTypography-body2.description {
  @include generalSans(500);
  font-size: 16px;
  line-height: 22px;
  color: #8d8b8b;
  padding: 0 5rem;
}
.accordian_title {
  @include generalSans(600);
  color: #000;
  font-size: 16px;
}
.claim_email_info {
  @include generalSans(500);
  font-size: 14px;
  color: #8d8b8b;
}

.onboarding {
  .MuiTypography-h1 {
    font-family: "Codec Pro Bold";
    font-size: 61px;
    line-height: 69px;
    color: #1a1616;
  }
  .MuiTypography-body1 {
    @include generalSans(500);
    font-size: 32px;
    color: #504d4d;
  }
  .MuiButton-containedPrimary {
    background: linear-gradient(
      90deg,
      #ed1f23 0.12%,
      #ed1f23 8.11%,
      #ed1f23 15.51%,
      #ed2023 22.41%,
      #ee2023 28.89%,
      #ee2123 35.05%,
      #ef2223 40.99%,
      #ef2323 46.78%,
      #f02423 52.53%,
      #f12523 58.33%,
      #f12723 64.27%,
      #f22823 70.43%,
      #f32a23 76.91%,
      #f42c23 83.81%,
      #f52d23 91.21%,
      #f62f23 99.2%
    );
    @include generalSans(700);
    color: #ffffff;
    font-size: 26.6233px;
    border-radius: 10.6493px;
    box-shadow: 0px 12px 41px -1px rgba(237, 31, 35, 0.14);
  }
  .MuiButton-outlinedSecondary {
    margin-top: 1rem;
    border: 1px solid #1a1616;
    border-radius: 8px;
    width: 336px;
    @include generalSans(600);
    color: #1a1616;
    font-size: 16px;
  }
}

.contactus_cta {
  .MuiTypography-body1 {
    @include generalSans(500);
    font-size: 32px;
    color: #504d4d;
  }
  .MuiButton-outlinedSecondary {
    margin-top: 1rem;
    border: 1px solid #1a1616;
    border-radius: 8px;
    width: 336px;
    @include generalSans(600);
    color: #1a1616;
    font-size: 16px;
  }
}
@media screen and (max-width: 1279px) {
  .popup-modal {
    position: absolute !important;
    left: 10rem;
  }
  .claim-modal {
    position: absolute !important;
    left: 10rem;
  }
}
.slick-slider {
  overflow: hidden;
}

.poll-reward-modal {
  .linear-progress-bar {
    position: relative;
    margin: 0.5rem 0;
    .choices-result {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
    .choice-text {
      font-family: "Inter", sans-serif;
      font-size: 13px;
      color: #000;
      font-weight: 600;
      left: 0;
      text-align: center;
      padding: 0 0.8rem;
    }
    .MuiLinearProgress-root {
      // background: rgba(26, 117, 255, 0.1);
      border: 1px solid #1a1616;
      .MuiLinearProgress-bar {
        cursor: pointer !important;
        // background: rgba(26, 117, 255, 0.4);
      }
    }
    &.selected {
      .MuiLinearProgress-root {
        cursor: pointer !important;
        // background: rgba(26, 117, 255, 0.4);
        border: 1px solid #1a1616;
      }
    }
    &.result-given {
      .MuiLinearProgress-root {
        cursor: default !important;
        // background: white;
        border: 1px solid #1a1616;
        .MuiLinearProgress-bar {
          cursor: default !important;
          // background: rgba(26, 117, 255, 0.1);
        }
      }
      .choices-result {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        cursor: default !important;
      }
    }
    &.result-given.selected {
      .MuiLinearProgress-root {
        cursor: pointer !important;
        // background: white;
        border: 1px solid #1a1616;
        .MuiLinearProgress-bar {
          cursor: pointer !important;
          // background: rgba(26, 117, 255, 0.4);
        }
      }
    }
  }
  .option-area {
    padding: 1rem;
    font-family: "Inter", sans-serif;
    position: relative;
    font-size: 13px;
    color: #000;
    font-weight: 600;
    background: rgba(26, 117, 255, 0.1);
    border: 1px solid #1a1616;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
    cursor: pointer;
    overflow: hidden;
    span {
      z-index: 20;
    }
    &.selected {
      background: rgba(26, 117, 255, 0.4);
    }
  }

  .confirm-btn {
    margin-top: 3rem;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 15px;
  }
  .merch-category {
    @include generalSans(600);
    font-size: 13px;
    color: #1a1616;
    margin-left: 5px;
  }
  .close-btn {
    color: $text-color;
    @include generalSans(700);
    font-size: $normal-font-size;
    border: 1px solid $theme-border;
    padding: 0.6rem 2rem;
  }
}

.domainCname {
  .MuiTypography-body2 {
    @include generalSans(600);
    font-size: 16px;
    color: #000000;
  }
}

.deleteResetModal {
  .MuiTypography-h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 130.9%;
  }
  .MuiTypography-h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130.9%;

    color: rgba(34, 51, 84, 0.5);
  }
}

.step3RewardDetail {
  .drop-category {
    display: inline-flex;
    border-radius: 30px;
    background: rgba(158, 205, 237, 0.15);
    padding: 8px 10px;
    .MuiTypography-body1 {
      @include generalSans(600);
      font-size: 13px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.26px;
    }
  }
  .title {
    margin-top: 20px;
    @include generalSans(700);
    font-size: 32px;
    color: #1a1616;
  }
  .description {
    margin-top: 20px;
    color: #1a1616;
    @include generalSans(500);
    font-size: 13px;
  }
  .statusbox {
    .heading {
      color: #000;
      @include generalSans(700);
      font-size: 16px;
    }
    .non-heading {
      color: #000;
      @include generalSans(500);
      font-size: 16px;
    }
  }
  .collectnTitle {
    color: #1a1616;
    @include generalSans(700);
    font-size: 16px;
  }
  .collectnIndvTitle {
    color: #1a1616;
    @include generalSans(600);
    font-size: 13px;
    margin-left: 8px;
  }
  .impDt {
    .MuiTypography-body1 {
      color: #1a1616;
      @include generalSans(500);
      font-size: 13px;
    }
    .MuiTypography-h6 {
      color: #1a1616;
      @include generalSans(700);
      font-size: 20px;
    }
  }

  .taskTitle {
    color: #1a1616;
    @include generalSans(600);
    font-size: 25px;
  }
  .tasksBox {
    .head.MuiTypography-body1 {
      color: #1a1616;
      @include generalSans(600);
      font-size: 13px;
      letter-spacing: 0.26px;
    }
    .subhead.MuiTypography-body1 {
      color: #aeacac;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      letter-spacing: 0.26px;
      .MuiTypography-body1 {
        font-family: Inter;
        font-size: 10px;
      }
    }
  }
}
.audience {
  .no-membership {
    box-shadow: 0px 2px 2px 0px rgba(159, 162, 191, 0.32),
      0px 9px 16px 0px rgba(159, 162, 191, 0.18);
    background: linear-gradient(
        180deg,
        rgba(12, 139, 244, 0.3) 0%,
        rgba(125, 250, 212, 0.3) 50.84%,
        rgba(235, 246, 255, 0.3) 100%
      ),
      #fff;
    border: 3px solid #000 !important;
    .MuiTypography-h2 {
      color: #000;
      @include generalSans(700);
      font-size: 17.924px;
    }
    .MuiTypography-body2 {
      color: #000;
      @include generalSans(500);
      font-size: 14.339px;
    }
    .MuiButton-containedInfo {
      margin-top: 8.96px;
      border-radius: 6px;
      background: var(
        --gradient-interface-cta-gradient,
        linear-gradient(
          264deg,
          #2a94ec 0%,
          #2994ec 8.07%,
          #2993ec 15.54%,
          #2893ec 22.5%,
          #2792ed 29.04%,
          #2690ed 35.26%,
          #248fed 41.25%,
          #228dee 47.1%,
          #208bee 52.9%,
          #1e88ef 58.75%,
          #1b86f0 64.74%,
          #1983f1 70.96%,
          #1680f1 77.5%,
          #137df2 84.46%,
          #0f7af3 91.93%,
          #0c77f4 100%
        )
      );
    }
  }
  .MuiCard-root {
    cursor: pointer;
    border: 1px solid #504d4d;
    border-radius: 6px;
    box-shadow: 0px 2px 2px 0px rgba(159, 162, 191, 0.32),
      0px 9px 16px 0px rgba(159, 162, 191, 0.18);
  }
  .MuiCard-root.selected {
    border: 3px solid #ed1f23;
  }
  .MuiTypography-h2 {
    color: #000;
    font-family: "General Sans Semibold" !important;
    font-size: 13px;
    letter-spacing: 0.26px;
  }
  .MuiTypography-body2 {
    color: #aeacac;
    @include generalSans(500);
    font-size: 13px;
  }
}

.MuiTypography-body2 {
  a {
    color: #ed1f23 !important;
  }
}

.charTxt {
  height: 21.5px !important;
}
.demoBanner {
  background-color: #1a1616;
  text-align: center;
  padding: 20px 0;
  .MuiTypography-body1 {
    color: #faa21d;
    @include generalSans(500);
    font-size: 16px;
    strong {
      @include generalSans(600);
    }
    .MuiLink-root {
      color: #faa21d;
      @include generalSans(500);
      font-size: 16px;
      text-decoration: underline;
    }
  }
}

.MuiBreadcrumbs-ol {
  .MuiBreadcrumbs-li {
    .MuiTypography-root {
      @include generalSans(400);
      font-size: 13px;
    }
    &:last-child {
      .MuiTypography-root {
        @include generalSans(600);
        font-size: 13px;
      }
    }
  }
}

.selectedSocialActivity {
  .MuiTypography-h2 {
    @include generalSans(700);
    font-size: 20px;
  }
  .activity-chip-box {
    .MuiChip-root {
      border-radius: 1000px;
      border: 1px solid var(--black-black-01, #1a1616);
      padding: 10px 7px;
      .MuiChip-label {
        padding: 10px 0;
      }
      .MuiTypography-body1 {
        @include generalSans(500);
        font-size: 13px;
      }
    }
    margin-top: 1rem;
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0rem;
    }
  }
}

.targetCollectionSelection {
  .MuiSelect-select {
    .MuiBox-root {
      font-size: 20px;
      @include generalSans(500);
      color: #1a1616;
    }
    .collection-image {
      height: 30px;
      width: 30px;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
}

.selectedTraits {
  .MuiTypography-h2 {
    @include generalSans(700);
    font-size: 20px;
  }
}

.targetingCollectionAcc {
  .MuiAccordion-root {
    &:last-child {
      border-bottom: 1px solid #000 !important;
    }
  }
}

footer {
  span {
    @include generalSans(400);
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65) !important;
    .MuiLink-root {
      @include generalSans(400);
      font-size: 13px;
      color: rgba(0, 0, 0, 0.65) !important;
    }
  }
}
.ck-editor__editable_inline {
  min-height: 120px;
}

.membership-pill {
  .sponsor-name {
    @include generalSans(600);
    font-size: 13px;
    padding-left: 6px;
    color: #000;
  }
}

.MuiTypography-h2.no-membership-title {
  font-family: "General Sans" !important;
  font-size: 25px;
}

.two-line-text {
  font-size: 1rem;
  line-clamp: 2 !important;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #000;
  word-break: break-word;
  font-family: General Sans bold;
  text-align: center;
}
.sidebar_focused {
  position: relative;
  .basic_manu {
    visibility: hidden;
  }
  &:hover .basic_manu {
    visibility: visible;
  }
}
.navbar_dropdown {
  ul {
    // padding: 0px !important;
    background: white;
    border-radius: 8px !important;
    padding: 0px !important;
    box-shadow: none !important;
    width: "134px" !important;
    // height: 82px !important;
    div {
      border-radius: 8px !important;
      background: #f3f3f3;
      padding: 15px 10px !important;

      li {
        box-shadow: 0px !important;
        background: "#F3F3F3" !important;

        &:hover {
          background: "#F3F3F3" !important;
        }
      }
    }
  }
}
.navbar_dropdown_list {
    .MuiButtonBase-root{
        color: #1A1616 !important;   
    }
    .cate_delete_modal {
        padding: 0px 0px !important;
        font-size: 14px;
        color: #1A1616;
        font-family: $secondary-font;
        font-weight: 700;
        width: 100%;
        .MuiBox-root {
          padding: 0px !important;
          button {
            text-align: left !important;
            display: block !important;
            height: 16px !important;
            width: full;
            &:hover {
              background: none !important;
            }
          }
        }
      }
      
  .MuiPaper-root {
    width: 134px !important;
    // height: 82px !important;
    border: 1px solid #1A1616;
    border-radius: 8px;
    background: #F3F3F3;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    ul {
      li {
        // color: #1a1616 !important;
        &:hover {
          background: #f3f3f3 !important;
        }
      }
    }
  }
}
// user_box_dropdown
.user_box_dropdown {
  bottom: 0px;
}

.sidebar_focused {
  &:focus {
    background: #f3f3f3 !important;
  }
  &:active {
    background: #f3f3f3 !important;
  }
}
