body {
    min-width: 1280px;
    overflow-x: hidden;
}
@media screen and (max-width:1279px) {
    body {
        overflow-x: auto !important;
    }
}
.color_field{
    margin-left: auto !important;
}
/* 
.nav-link{
    text-decoration: none;
    color: #6E759F;
    margin:0 1.9rem;
} */
.MuiOutlinedInput-notchedOutline{
    border-radius: 4px;
    border: 1px solid rgba(34, 51, 84, 0.5);
}
.css-1ede6kb-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    color: #223344;
}
input[type="color"] {
    border: 1px solid black;
    padding-top: 0% !important;
    border-radius: 6px;
    height: 4em !important;
    width: 4.4375em;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}
.MuiAutocomplete-inputRoot .MuiSvgIcon-root{
    fill: black;
}
.MuiAutocomplete-root ::placeholder{
    color: black;
}
.merchandise-form .Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid rgba(34, 51, 84, 0.5) !important;
}
.merchandise-form label.Mui-focused{
    color: rgba(34, 51, 84, 0.7) !important;
}