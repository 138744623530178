@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "Codec Pro Bold";
  src: url("../fonts/Codec Pro Bold.ttf");
}
@font-face {
  font-family: "Codec Pro Extrabold";
  src: url("../fonts/Codec Pro Extrabold.ttf");
}
@font-face {
  font-family: "General Sans Semibold";
  src: url("../fonts/GeneralSans-Semibold.otf");
}
@font-face {
  font-family: "General Sans Medium";
  src: url("../fonts/GeneralSans-Medium.otf");
}
@font-face {
  font-family: "General Sans bold";
  src: url("../fonts/GeneralSans-Bold.otf");
}
@font-face {
  font-family: "General Sans";
  src: url("../fonts/GeneralSans-Regular.otf");
}
@font-face {
  font-family: "Codec Pro Heavy";
  src: url("../fonts/Codec Pro Heavy.ttf");
}
.inter-font {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.font-gs-b {
  font-family: General Sans bold;
  font-weight: 700;
}

.font-gs-sb {
  font-family: General Sans Semibold;
  font-weight: 600;
}

.font-gs-md {
  font-family: General Sans Medium;
  font-weight: 500;
}

.spinner {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #000000 94%, rgba(0, 0, 0, 0)) top/2.9px 2.9px no-repeat, conic-gradient(rgba(0, 0, 0, 0) 30%, #000000);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 2.9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;
  margin-left: 0.5rem;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
.additional-info-note {
  font-size: 14px;
  font-family: "General Sans";
  color: #8d8b8b;
  padding-left: 1rem;
  padding-top: 0.7rem;
}

.MuiStepLabel-label {
  font-family: General Sans Semibold !important;
  font-size: 14.5px;
  color: #1a1616 !important;
  margin-top: 10px !important;
}

.MuiStepLabel-label.Mui-disabled {
  font-family: "General Sans" !important;
  font-size: 13px;
  color: #1a1616 !important;
  margin-top: 10px !important;
}

.navbar {
  background-color: linear-gradient(127.55deg, #141e30 3.73%, #243b55 92.26%);
}
.navbar .nav-link {
  text-decoration: none;
  color: #1a1616;
  margin: 0 1.5rem;
  cursor: pointer;
  font-family: "General Sans Medium";
}
.navbar .nav-link.active {
  position: relative;
}
.navbar .nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 5px;
  background-color: #ed1f23;
  border-radius: 5rem;
}

.headerBox .btn-outlined-secondary {
  color: #1a1616 !important;
  font-family: General Sans Semibold !important;
  font-size: 1rem;
  border: 1px solid #504d4d;
  border-radius: 6px;
  background-color: #fff;
  padding: 0.7rem 1.3rem;
}
.headerBox .btn-outlined-secondary:hover {
  text-decoration: none;
}
.headerBox .page-text {
  color: #1a1616;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.headerBox .page-text:nth-child(1) {
  font-weight: 700;
}
.headerBox .expand-icon {
  color: #1a1616;
}

.dropdown-box {
  min-width: 200px;
  background: linear-gradient(90deg, #ed1f23 0.12%, #ed1f23 8.11%, #ed1f23 15.51%, #ed2023 22.41%, #ee2023 28.89%, #ee2123 35.05%, #ef2223 40.99%, #ef2323 46.78%, #f02423 52.53%, #f12523 58.33%, #f12723 64.27%, #f22823 70.43%, #f32a23 76.91%, #f42c23 83.81%, #f52d23 91.21%, #f62f23 99.2%);
  border-radius: 0%;
  padding: 0%;
}

.sidebar {
  border-top: 1px solid #504d4d;
}
.sidebar .sidebar-text {
  color: #1a1616;
  text-align: left;
}
.sidebar .sidebar-text.title {
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
}
.sidebar .sidebar-text.sub-head {
  font-size: 1.25rem;
  font-family: General Sans Semibold !important;
}
.sidebar .sidebar-text.description {
  font-size: 1rem;
  font-family: "General Sans Medium";
}

.homepage .page-head {
  color: #1a1616;
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
}
.homepage .page-subhead {
  font-family: "General Sans";
  font-size: 2rem;
}
.homepage .btn {
  background: linear-gradient(90deg, #ed1f23 0.12%, #ed1f23 8.11%, #ed1f23 15.51%, #ed2023 22.41%, #ee2023 28.89%, #ee2123 35.05%, #ef2223 40.99%, #ef2323 46.78%, #f02423 52.53%, #f12523 58.33%, #f12723 64.27%, #f22823 70.43%, #f32a23 76.91%, #f42c23 83.81%, #f52d23 91.21%, #f62f23 99.2%) !important;
  border-radius: 64px;
  padding: 1rem;
  font-family: "General Sans Medium";
  font-size: 1.563rem;
  font-size: 16px;
  padding: 0.7rem 2rem;
}
.homepage .btn .btn-icon {
  font-size: 2.5rem;
}

.dropdown-popper {
  border: 1px solid #1a1616 !important;
  border-radius: 8px;
  padding: 0 2rem;
}
.dropdown-popper a {
  text-decoration: none !important;
  cursor: pointer;
}
.dropdown-popper a .dropdown-item {
  display: flex;
  min-width: 200px;
  padding: 1rem 0;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #d0cfcf;
}
.dropdown-popper a .dropdown-item img {
  width: 29.17px;
}
.dropdown-popper a .dropdown-item .dropdown-text {
  color: #1a1616;
  font-size: 1rem;
  font-family: General Sans Semibold !important;
  margin-bottom: 0%;
  margin-left: 2px;
}
.dropdown-popper a:last-child .dropdown-item {
  border-bottom: none;
}

.dropdown-settings-popper {
  border: 1px solid #1a1616 !important;
  border-radius: 0px;
  padding: 0;
  width: 210px;
  background-color: #fff;
}
.dropdown-settings-popper .list {
  padding: 0.5rem 0;
  display: flex;
  min-width: 100px;
  border-radius: 0%;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(26, 22, 22, 0.1);
}
.dropdown-settings-popper .list:last-child {
  border-bottom: 0px solid red;
}
.dropdown-settings-popper .list .list-item .MuiListItemText-primary {
  color: #1a1616;
  font-size: 1rem;
  font-family: General Sans Semibold !important;
  margin-bottom: 0%;
  margin-left: 2px;
}

.list-popper {
  border: 1px solid #1a1616 !important;
  border-radius: 8px;
  padding: 0 1rem;
  max-width: 250px;
}
.list-popper .MuiBox-root {
  background-color: white;
}
.list-popper .list {
  border-bottom: 1px solid #d0cfcf;
  padding: 0.5rem 0;
  display: flex;
  min-width: 200px;
  border-radius: 0%;
  align-items: center;
  justify-content: flex-start;
}
.list-popper .list:last-child {
  border-bottom: none !important;
}
.list-popper .list .list-item .MuiListItemText-primary {
  color: #1a1616;
  font-size: 1rem;
  font-family: General Sans Semibold !important;
  margin-left: 2px;
  margin-bottom: 0%;
}

.registration-page .title {
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
  color: #1a1616;
}
.registration-page .registration-form {
  border: 1px solid #504d4d;
  border-radius: 6px;
}
.registration-page .terms-conditions {
  font-family: "General Sans Medium";
  color: #223354 !important;
}
.registration-page input.MuiOutlinedInput-input::-moz-placeholder {
  color: #8d8b8b;
}
.registration-page input.MuiOutlinedInput-input::placeholder {
  color: #8d8b8b;
}
.registration-page input.MuiOutlinedInput-input {
  color: #8d8b8b;
  opacity: 1 !important;
}
.registration-page input.MuiOutlinedInput-input::-moz-placeholder {
  opacity: 1 !important;
}
.registration-page input.MuiOutlinedInput-input::placeholder {
  opacity: 1 !important;
}
.registration-page .create-btn {
  font-family: General Sans Semibold !important;
}

.almost-done-page .title {
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
  color: #1a1616;
}
.almost-done-page .para-text {
  font-family: "General Sans";
  font-size: 2rem;
}
.almost-done-page .resend-btn {
  font-family: "General Sans Medium";
}

.rewards-section .title {
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
  color: #1a1616;
}
.rewards-section .subtitle {
  font-size: 16px;
  font-family: "General Sans Medium";
  color: #8d8b8b;
}
.rewards-section .btn {
  border-radius: 6px;
  padding: 0.6rem 2rem;
  font-family: "General Sans Medium";
  font-size: 1rem;
}
.rewards-section .no-active-drops {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 425px;
}
.rewards-section .no-active-drops img {
  -o-object-fit: contain;
     object-fit: contain;
}
.rewards-section .no-active-drops .helper-text {
  color: #1a1616;
  font-family: "General Sans Medium";
  text-align: center;
  font-size: 1.563rem;
}
.rewards-section .no-active-drops a {
  color: #ed1f23;
  font-size: 1.563rem;
  font-family: "General Sans Medium";
  text-decoration: none;
  text-align: center;
  display: block;
}

.collections .title {
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
  color: #1a1616;
}
.collections .btn {
  border-radius: 6px;
  padding: 0.6rem 2rem;
  font-family: "General Sans Medium";
  font-size: 1rem;
}
.collections .no-collections {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 425px;
}
.collections .no-collections .helper-text {
  color: #1a1616;
  font-family: "General Sans Medium";
  text-align: center;
  font-size: 1.563rem;
}
.collections .no-collections a {
  color: #ed1f23;
  font-size: 1.563rem;
  font-family: "General Sans Medium";
  text-decoration: none;
  text-align: center;
  display: block;
}
.collections .table-card {
  max-width: 988px;
  border-radius: 6px;
  border: 1px solid #504d4d;
  padding: 1rem 1.5rem;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32);
}
.collections .table-card .table {
  border: 1px solid #504d4d;
}
.collections .table-card .table .table-head {
  background-color: #f3f3f3;
  border-bottom: 1px solid #504d4d;
  font-family: General Sans Semibold !important;
  color: #504d4d;
  font-size: 13px;
}
.collections .table-card .table .table-head:nth-child(1) {
  padding-left: 2.8rem;
}
.collections .table-card .table .collection-row {
  background-color: rgba(235, 246, 255, 0.3);
}
.collections .table-card .table .collection-row th:nth-child(1) {
  padding-left: 2.8rem;
}
.collections .table-card .table .collection-row td,
.collections .table-card .table .collection-row th {
  border-bottom: 1px solid #504d4d;
}
.collections .table-card .table .collection-row .collection-image {
  height: 70px !important;
  width: 70px !important;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px !important;
}
.collections .table-card .table .collection-row .collection-name {
  font-family: "General Sans Bold";
  color: #343030;
  font-size: 1rem;
}
.collections .table-card .table .collection-row .collection-size {
  font-family: "General Sans Bold";
  font-size: 1rem;
  color: #1a1616;
}
.collections .table-card .table .collection-row .collection-description {
  font-family: "General Sans";
  font-size: 13px;
  color: #1a1616;
}
.collections .table-card .table .collection-row .action-icon {
  color: #1a1616;
  font-weight: bold;
  padding: 0px;
  justify-content: flex-start;
}

.sidebar-drop-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sidebar-drop-box .heading {
  font-family: General Sans Semibold !important;
  font-size: 1.563rem;
  color: #1a1616;
  text-align: center;
}

.multistep-form {
  border: 1px solid #504d4d;
  border-radius: 6px;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32);
}
.multistep-form .subtitle {
  font-size: 16px;
  font-family: "General Sans";
  color: #8d8b8b;
}
.multistep-form .step-1 .title {
  color: #1a1616;
  font-size: 2rem;
  font-family: "General Sans Bold";
  text-align: center;
}
.multistep-form .step-1 .subtitle {
  font-size: 16px;
  font-family: "General Sans";
  color: #8d8b8b;
}
.multistep-form .step-1 .btn-outlined {
  padding: 0.4rem 1.8rem;
  font-family: General Sans Semibold !important;
}
.multistep-form .step-1 .drop-type-card {
  cursor: pointer;
  position: relative;
  height: 150px;
  width: 150px;
  border: 1px solid #504d4d;
}
.multistep-form .step-1 .drop-type-card.selected {
  border: 3px solid #ed1f23;
}
.multistep-form .step-1 .drop-type-card .card-content {
  border-top: 1px solid #504d4d;
}
.multistep-form .step-1 .drop-type-card .drop-title {
  color: #000;
  font-family: General Sans Semibold !important;
  letter-spacing: 0.02em;
  font-size: 13px;
  word-break: break-all;
}
.multistep-form .step-1 .drop-type-card .drop-description {
  color: #a1a1a1;
  font-family: "General Sans Medium";
  letter-spacing: 0.02em;
  font-size: 10px;
  word-break: break-word;
  line-height: 120%;
}
.multistep-form .step-1 .table {
  max-width: 600px;
  min-height: 10rem;
  border: 1px solid #504d4d;
}
.multistep-form .step-1 .table .table-head {
  background-color: #f3f3f3;
  border-bottom: 1px solid #504d4d;
  font-family: General Sans Semibold !important;
  color: #504d4d;
  font-size: 13px;
}
.multistep-form .step-1 .table .table-head:nth-child(1) {
  padding-left: 3rem;
}
.multistep-form .step-1 .table .collection-table .MuiTableCell-root {
  border-top: 1px solid #504d4d;
  background: rgba(235, 246, 255, 0.3);
}
.multistep-form .step-1 .table .collection-table .collection-image {
  height: 70px;
  width: 70px;
  border-radius: 6px;
  margin-right: 10px;
}
.multistep-form .step-1 .table .collection-table .collection-name {
  font-family: "General Sans Bold";
  color: #343030;
  font-size: 1rem;
}
.multistep-form .step-1 .table .collection-table .collection-size {
  font-family: "General Sans Bold";
  font-size: 1rem;
  color: #1a1616;
}
.multistep-form .step-2 .title {
  color: #1a1616;
  font-size: 2rem;
  font-family: "General Sans Bold";
  text-align: center;
}
.multistep-form .step-3 .title {
  color: #1a1616;
  font-size: 2rem;
  font-family: "General Sans Bold";
  text-align: center;
}

.preview-dropbox {
  width: 326px;
  height: 517px;
  border: 1px solid #504d4d;
  border-radius: 6px;
  position: relative;
}
.preview-dropbox .card-header {
  height: 18px;
}
.preview-dropbox .card-content {
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.preview-dropbox .drop-category {
  font-family: General Sans Semibold !important;
  font-size: 13px;
  letter-spacing: 0.02em;
  color: #1a1616;
  padding-left: 5px;
}
.preview-dropbox .image-com {
  border-top: 1px solid #504d4d;
  border-bottom: 1px solid #504d4d;
}
.preview-dropbox .no-image {
  background-color: #ffebeb;
  border-top: 1px solid #504d4d;
  border-bottom: 1px solid #504d4d;
}
.preview-dropbox .MuiSkeleton-root {
  border-radius: 0%;
}
.preview-dropbox .drop-title {
  font-family: General Sans Semibold !important;
  font-size: 1rem;
  color: #223354;
}
.preview-dropbox .drop-description {
  font-family: "General Sans Medium";
  font-size: 13px;
  color: #aeacac;
  word-break: break-word;
}
.preview-dropbox .ends-text {
  font-family: "General Sans Medium";
  font-size: 10px;
  text-transform: uppercase;
  color: #aeacac;
  text-align: right;
}
.preview-dropbox .ends-time {
  font-family: General Sans Semibold !important;
  font-size: 1rem;
  color: #1a1616;
  text-align: right;
}
.preview-dropbox .claimed-count {
  font-family: Roboto, sans-serif;
  text-align: right;
}
.preview-dropbox .claimed-count .claimed {
  font-family: "General Sans Medium";
  font-size: 10px;
  color: #aeacac;
  text-transform: uppercase;
}
.preview-dropbox .claimed-count .claimed-quantity {
  font-family: General Sans Semibold !important;
  color: #1a1616;
  font-size: 1rem;
  line-height: 16px;
}
.preview-dropbox .claimed-count .claimed-limit {
  font-family: "General Sans Medium";
  font-size: 10px;
  color: #8d8b8b;
}
.preview-dropbox .sponsor-border {
  border: 2px solid #fff;
}
.preview-dropbox .more-sponsors-box {
  cursor: pointer;
  height: 32px;
  width: 32px;
  background-color: #fff !important;
  z-index: 10;
  margin-left: -1rem;
  border: 2px solid #223354;
  border-radius: 50%;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #223354;
}
.preview-dropbox .sponsors-area {
  height: 45px;
}
.preview-dropbox .sponsors-area .sponsor-name {
  font-family: General Sans Semibold !important;
  font-size: 13px;
  padding-left: 10px;
  color: #000;
}

.dropbox {
  width: 328px;
  border: 1px solid #504d4d;
  position: relative;
  height: 517px;
  width: 326px;
  margin-bottom: 0.8rem;
  border-radius: 6px;
}
.dropbox .card-header {
  height: 18px;
}
.dropbox .sponsor-border {
  border: 2px solid #fff;
}
.dropbox .card-content {
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dropbox .more-sponsors-box {
  cursor: pointer;
  height: 32px !important;
  width: 32px !important;
  background-color: #fff !important;
  z-index: 10;
  margin-left: -1rem;
  border: 2px solid #223354;
  border-radius: 50%;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #223354;
  font-size: 12px;
}
.dropbox .drop-category {
  font-family: General Sans Semibold !important;
  font-size: 13px;
  letter-spacing: 0.02em;
  color: #1a1616;
  padding-left: 5px;
}
.dropbox .image-com {
  border-top: 1px solid #504d4d;
  border-bottom: 1px solid #504d4d;
}
.dropbox .no-image {
  background-color: #ffebeb;
  border-top: 1px solid #504d4d;
  border-bottom: 1px solid #504d4d;
}
.dropbox .MuiSkeleton-root {
  border-radius: 0%;
}
.dropbox .drop-title {
  font-family: General Sans Semibold !important;
  font-size: 1rem;
  color: #223354;
}
.dropbox .drop-description {
  font-family: "General Sans Medium";
  font-size: 13px;
  color: #aeacac;
  word-break: break-word;
}
.dropbox .ends-text {
  font-family: "General Sans Medium";
  font-size: 10px;
  text-transform: uppercase;
  color: #aeacac;
  text-align: right;
}
.dropbox .ends-time {
  font-family: General Sans Semibold !important;
  font-size: 1rem;
  color: #1a1616;
  text-align: right;
}
.dropbox .started-text {
  font-family: "General Sans Medium";
  font-size: 10px;
  text-transform: uppercase;
  color: #aeacac;
}
.dropbox .started-time {
  font-family: General Sans Semibold !important;
  font-size: 1rem;
  color: #1a1616;
}
.dropbox .claimed-count {
  text-align: right;
}
.dropbox .claimed-count .claimed {
  font-family: "General Sans Medium";
  font-size: 10px;
  color: #aeacac;
  text-transform: uppercase;
}
.dropbox .claimed-count .claimed-quantity {
  font-family: General Sans Semibold !important;
  color: #1a1616;
  font-size: 1rem;
  line-height: 16px;
}
.dropbox .claimed-count .claimed-limit {
  font-family: "General Sans Medium";
  font-size: 10px;
  color: #8d8b8b;
}
.dropbox .sponsors-area .sponsor-name {
  font-family: General Sans Semibold !important;
  font-size: 13px;
  padding-left: 10px;
  color: #000;
}

.sponsorcol_image {
  border: 1.13514px solid #ffffff;
}

.sponsor_title {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  padding-left: 5px;
}

.settings .MuiTypography-h3 {
  font-family: "General Sans Bold";
  font-size: 20px;
  color: #000000;
}
.settings .MuiTypography-h6 {
  font-family: "General Sans Medium";
  font-size: 16px;
  color: #8d8b8b;
}
.settings .title {
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
}
.settings .btn-text {
  font-family: General Sans Semibold !important;
}
.settings .subhead {
  font-family: "General Sans";
  margin-top: 0px;
  color: #1a1616;
  opacity: 0.7;
}
.settings .account-settings .account-card {
  border: 1px solid #504d4d;
  border-radius: 6px;
}
.settings .account-settings .resend-btn {
  color: #1a1616;
  font-family: General Sans Semibold !important;
  text-decoration: underline;
}
.settings .claim-settings .claim-heading {
  font-family: General Sans Semibold !important;
  font-size: 25px;
  color: #000;
}
.settings .claim-settings .claim-card {
  border: 1px solid #504d4d;
  border-radius: 6px;
}
.settings .claim-settings .hostname {
  font-family: General Sans Semibold !important;
  font-size: 1rem;
}
.settings .advanced-settings .MuiLink-root {
  text-decoration: underline;
}
.settings .advanced-settings .MuiTypography-h3 {
  font-family: "General Sans Bold";
  font-size: 20px;
  color: #000000;
}
.settings .advanced-settings .MuiTypography-h5 {
  font-family: "General Sans Medium";
  font-size: 16px;
  color: #8d8b8b;
  font-weight: normal;
}
.settings .advanced-settings .MuiTypography-h4 {
  font-family: "General Sans Medium";
  font-size: 20px;
  color: #000000;
}
.settings .advanced-settings .MuiTypography-h5 {
  font-family: "General Sans Medium";
  font-size: 16px;
  color: #8d8b8b;
}
.settings .advanced-settings .MuiTypography-body1 {
  font-family: "General Sans Medium";
  color: #8d8b8b;
  font-size: 13px;
  line-height: 18px;
}
.settings .domain-settings .MuiLink-root {
  text-decoration: underline;
}
.settings .domain-settings .MuiTypography-h3 {
  font-family: "General Sans Bold";
  font-size: 20px;
  color: #000000;
}
.settings .domain-settings .MuiTypography-h4 {
  font-family: "General Sans Medium";
  font-size: 20px;
  color: #000000;
}
.settings .domain-settings .MuiTypography-h5 {
  font-family: "General Sans Medium";
  font-size: 16px;
  color: #000000;
}
.settings .domain-settings .MuiTypography-h6 {
  font-family: "General Sans Medium";
  font-size: 16px;
  color: #8d8b8b;
}
.settings .domain-settings .MuiTypography-body1 {
  font-family: "General Sans Medium";
  color: #8d8b8b;
  font-size: 13px;
  line-height: 18px;
}
.settings .domain-settings .MuiRadio-colorPrimary:not(.Mui-checked) + .MuiFormControlLabel-label {
  opacity: 0.5;
}
.settings .design-settings .MuiTypography-h4 {
  font-family: General Sans Semibold !important;
  font-size: 16px;
  color: #000000;
}
.settings .design-settings .MuiTypography-h5 {
  font-family: "General Sans Medium";
  font-size: 16px;
  color: #000000;
}
.settings .plan-settings .heading {
  font-family: "General Sans Bold";
}
.settings .plan-settings .subhead {
  font-family: "General Sans";
  padding-top: 0.7rem;
}

.modals {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32);
}
.modals .MuiDialog-container .MuiPaper-root {
  border-radius: 6px !important;
  box-shadow: none;
}
.modals .modal-card {
  border: 1px solid #504d4d;
  border-radius: 6px;
  box-shadow: none;
}
.modals .modal-card .coupon-card {
  background-color: rgba(34, 51, 84, 0.02);
  margin-top: 10px;
  box-shadow: none;
}
.modals .heading {
  text-align: center;
  font-family: "General Sans Bold";
  font-size: 2rem;
  color: #1a1616;
}
.modals .helper-text {
  font-family: "General Sans Medium";
  font-size: 1rem;
  color: rgba(34, 51, 84, 0.5);
  text-align: center;
  padding-top: 0.7rem;
}
.modals .title {
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
  font-size: 2rem;
}
.modals .btn-text {
  font-family: Roboto, sans-serif;
  font-weight: 700;
}

.preview-modal .modal-border {
  border: 1px solid #504d4d;
}
.preview-modal .preview-title {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #1a1616;
  position: absolute;
  top: -4rem;
  left: 18px;
}
.preview-modal .question {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}
.preview-modal .question-helper {
  font-family: "Inter", sans-serif;
  color: #343957;
  font-size: 14px;
}
.preview-modal .option-area {
  padding: 1rem;
  word-break: break-all;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  background: rgba(26, 117, 255, 0.1);
  border: 1px solid #1a1616;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  cursor: pointer;
}
.preview-modal .option-area.selected {
  background: rgba(26, 117, 255, 0.4);
}
.preview-modal .confirm-btn {
  margin-top: 3rem;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 15px;
}
.preview-modal .allow-list-card {
  background: #f6f8fb;
  border: 1px solid #cccedd;
  box-shadow: 0px 4px 21px rgba(24, 69, 136, 0.2);
  border-radius: 6px;
}
.preview-modal .allow-list-card .inner-al-card {
  background: rgba(34, 51, 84, 0.02);
  border-radius: 8px;
  box-shadow: none;
}
.preview-modal .allow-list-card .inner-al-card .detail-card {
  background-color: #fff;
  box-shadow: none;
  border-radius: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.preview-modal .allow-list-card .inner-al-card .detail-card .btn {
  border-radius: 6px;
  padding: 5px 10px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 14px;
}
.preview-modal .allow-list-card .inner-al-card .detail-card .helper-title {
  color: #223354;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 14px;
}
.preview-modal .allow-list-card .inner-al-card .detail-card .helper-description {
  color: rgba(34, 51, 84, 0.5);
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 700;
}
.preview-modal .modal-title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 1rem;
  color: #0c8bf4;
  word-break: break-all;
}
.preview-modal .heading {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 2rem;
  color: #223354;
  word-break: break-all;
}
.preview-modal .description {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #343957;
  text-align: center;
  margin: 1rem 0;
}
.preview-modal .merch-category {
  font-family: General Sans Semibold !important;
  font-size: 13px;
  color: #1a1616;
  margin-left: 5px;
}
.preview-modal .coupon-code {
  font-family: Space Mono;
  font-weight: 400;
  font-style: italic;
  color: #223354;
}
.preview-modal .go-to-site-btn {
  background-color: #0c8bf4;
  border-radius: 6px;
  width: 100%;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  margin: 10px 0;
}
.preview-modal .close-btn {
  color: #1a1616;
  font-family: "General Sans Bold";
  font-size: 1rem;
  border: 1px solid #504d4d;
  padding: 0.6rem 2rem;
}
.preview-modal .content-name {
  font-family: General Sans Semibold !important;
  margin-left: 10px;
}
.preview-modal .btn-text {
  font-family: Roboto, sans-serif;
  font-weight: 700;
}

.Mui-focused.MuiFormLabel-root {
  color: #504d4d;
}

.signup-successfull .title {
  text-align: center;
  font-size: 2.438rem;
  font-family: Codec Pro Bold;
  color: #1a1616;
}
.signup-successfull .description {
  text-align: center;
  font-size: 1.563rem;
  font-family: "General Sans";
  font-weight: 400;
}
.signup-successfull .connect-title {
  font-family: General Sans Semibold !important;
  text-align: center;
  font-size: 2.5rem;
  color: #1a1616;
}
.signup-successfull .login-desc {
  text-align: center;
  font-family: "General Sans";
  font-size: 1.5rem;
}
.signup-successfull .btn {
  background: linear-gradient(90deg, #ed1f23 0.12%, #ed1f23 8.11%, #ed1f23 15.51%, #ed2023 22.41%, #ee2023 28.89%, #ee2123 35.05%, #ef2223 40.99%, #ef2323 46.78%, #f02423 52.53%, #f12523 58.33%, #f12723 64.27%, #f22823 70.43%, #f32a23 76.91%, #f42c23 83.81%, #f52d23 91.21%, #f62f23 99.2%) !important;
  border-radius: 64px;
  padding: 1rem;
  font-family: "General Sans Medium";
  font-size: 1.563rem;
  font-size: 16px;
  padding: 0.7rem 2rem;
}
.signup-successfull .btn .btn-icon {
  font-size: 2.5rem;
}

.claim-modal .modal-card {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32);
  border: 1px solid #504d4d;
}
.claim-modal .claim-card .title {
  font-family: "General Sans Bold";
  font-size: 18.646px;
  color: #1a1616;
}
.claim-modal .claim-card .description {
  font-family: "General Sans Medium";
  font-size: 9.696px;
  color: #8d8b8b;
  padding: 0 5rem;
}
.claim-modal .merch-category {
  font-family: General Sans Semibold !important;
  font-size: 9.696px;
  color: #330a0a;
  padding-left: 5px;
}
.claim-modal .reward-title {
  font-family: "General Sans Bold";
  color: #223354;
  font-size: 18.646px;
}
.claim-modal .reward-description {
  font-family: "General Sans Medium";
  font-size: 9.696px;
  color: #8d8b8b;
}
.claim-modal .reward-card {
  border-radius: 6px;
  border: none;
  box-shadow: none;
}
.claim-modal .left-side .total-claim {
  font-family: Poppins, sans-serif;
  font-weight: 800;
  font-size: 2rem;
  color: #ebf9ff;
  font-style: italic;
}
.claim-modal .left-side .quantity {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: rgba(235, 249, 255, 0.7);
  font-style: italic;
}
.claim-modal .left-side .claimed-text {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-style: italic;
  color: rgba(235, 249, 255, 0.55);
  font-size: 14px;
}
.claim-modal .detail-card {
  background-color: #f3f3f3;
  box-shadow: none;
  border-radius: 8px;
}
.claim-modal .detail-card .started-text {
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: rgba(34, 51, 84, 0.5);
  font-size: 8.95px;
}
.claim-modal .detail-card .started-time {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 11.934px;
  color: #223354;
}
.claim-modal .detail-card .sponsor-name {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 8.95px;
  color: #000;
}
.claim-modal .detail-card .btn {
  width: 100%;
  font-family: General Sans Semibold !important;
  font-size: 11.934px;
}

.poll-form .title {
  font-family: "General Sans Bold";
  color: #1a1616;
  font-size: 2rem;
}
.poll-form .label-text {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #000;
  letter-spacing: 0.09px;
}
.poll-form .delete-choice-btn {
  background: #fff7eb;
  border-radius: 6px;
  height: 40px;
  min-width: 40px !important;
}

.gated-content-form .title {
  font-family: "General Sans Bold";
  color: #1a1616;
  font-size: 2rem;
}

.event-pass-form .title {
  font-family: "General Sans Bold";
  color: #1a1616;
  font-size: 2rem;
}
.event-pass-form .option_description {
  font-family: "General Sans Medium";
  color: #8d8b8b;
}

.MuiStepLabel-label,
.MuiStepLabel-label.Mui-active {
  font-family: "General Sans Medium";
}

.font-weight-600 {
  font-family: General Sans Semibold !important;
}

.publishing_options .MuiSwitch-thumb {
  background-color: #0a2033;
}
.publishing_options .Mui-checked .MuiSwitch-thumb {
  background: #f3f3f3;
  border: 1px solid #d0cfcf;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32);
}
.publishing_options .MuiSwitch-track {
  background: #cccedd;
  opacity: 0.5;
  box-shadow: inset 0px 1px 1px rgba(119, 126, 165, 0.25);
  border-radius: 6px;
}
.publishing_options .Mui-checked + .MuiSwitch-track,
.publishing_options .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background: #cccedd;
  opacity: 0.5;
  box-shadow: inset 0px 1px 1px rgba(119, 126, 165, 0.25);
  border-radius: 6px;
}
.publishing_options .MuiSwitch-switchBase.Mui-checked:hover {
  background-color: transparent;
}
.publishing_options .option_card {
  background-color: rgba(243, 243, 243, 0.5);
  border-radius: 10px;
}
.publishing_options .option_card .option_title {
  font-family: "General Sans Bold";
  font-size: 1.25rem;
  color: #1a1616;
}
.publishing_options .option_card .option_description {
  font-family: "General Sans Medium";
  color: #8d8b8b;
  font-size: 13px;
}

.MuiChip-filled {
  background-color: #ebf6ff;
}

.MuiInputLabel-root,
fieldset.MuiOutlinedInput-notchedOutline legend,
fieldset.MuiOutlinedInput-notchedOutline legend span {
  text-transform: uppercase;
}

/* .MuiStepper-root {
    background-color: rgba(26, 22, 22, 0.02);
} */
.MuiStepper-root {
  background-repeat: repeat-x;
  background-position: center 35px;
}

.MuiStepConnector-line {
  border-top-width: 0px !important;
}

.MuiStepLabel-root .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  border-radius: 50%;
  border: 5px solid #ffffff;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary:not(.Mui-selected) {
  color: rgba(26, 22, 22, 0.8);
}

.claim-blue-btn {
  background: linear-gradient(269.93deg, #2a94ec -1.34%, #2994ec 6.83%, #2993ec 14.4%, #2893ec 21.45%, #2792ed 28.07%, #2690ed 34.37%, #248fed 40.44%, #228dee 46.37%, #208bee 52.24%, #1e88ef 58.17%, #1b86f0 64.23%, #1983f1 70.53%, #1680f1 77.16%, #137df2 84.21%, #0f7af3 91.77%, #0c77f4 99.95%) !important;
  width: 100%;
  font-family: General Sans Semibold !important;
  font-size: 1rem;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  line-height: 1.75em !important;
}

.detail-box {
  font-family: "General Sans";
  font-size: 16px;
  color: #9099a9;
  font-size: 500;
}

.empty-helper-txt {
  font-family: "General Sans Medium";
  font-size: 25px;
  color: #1a1616;
  text-align: center;
}

.raffle-chip-label {
  font-family: General Sans Semibold !important;
  color: #1a1616;
  background-color: #ebfff9 !important;
}

.raffle-message {
  font-family: Inter, sans-serif;
  color: #343957;
  font-weight: 400;
  font-weight: 14px;
  line-height: 150%;
  text-align: center;
}

.raffle-end-timer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135.7%;
}

.raffle-end-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135.7%;
  color: #223354;
}

.draw-winner-label {
  background-color: #f3f3f3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 90px;
  height: 65px;
}
.draw-winner-label .MuiTypography-body1 {
  font-family: "General Sans Medium";
  color: #000000;
  font-size: 13px;
  line-height: 18px;
}
.draw-winner-label .MuiTypography-h3 {
  font-family: "General Sans Bold";
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.draw-raffle-label-msg {
  font-family: General Sans Semibold !important;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
}

.delete-action-icon {
  color: #1a1616;
  font-weight: bold;
  padding: 0px;
  justify-content: flex-start;
}

.raffle-th {
  font-family: General Sans Semibold !important;
}

.draw-raffle-note-msg {
  font-size: 13px;
  color: #1a1616;
}

.draw-raffle-note-msg-2 {
  font-size: 13px;
  color: #8d8b8b;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
  border-left: 1px solid black;
}

.MuiTableRow-root .MuiTableCell-root:last-child {
  border-right: 1px solid black;
}

.draw-raffle-email-label {
  font-family: "General Sans";
  font-size: 13px;
  color: #1a1616;
}

.draw-raffle-email-label strong {
  font-family: General Sans Semibold !important;
  color: #1a1616;
  color: #1a1616;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.coming-soon-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.react-datepicker__input-container > input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.loginModal .title {
  font-family: "General Sans Bold";
  font-size: 32px;
  color: #1a1616;
  margin-top: 45px;
  margin-bottom: 77px;
}
.loginModal .ortext {
  font-family: "General Sans Medium";
}
.loginModal .box-title {
  font-family: "General Sans Medium";
  font-size: 22px;
  color: #1a1616;
  text-align: center;
}
.loginModal .btn {
  border-radius: 6px;
  padding: 0.6rem 2rem;
  font-family: "General Sans Medium";
  font-size: 1rem;
  color: white;
}

.MuiInputBase-input {
  padding: 10px 14px;
}

.Add-image .MuiInputBase-input {
  padding: 16.5px 14px 16.5px 0;
}
.Add-image #image_preview {
  display: flex;
  align-items: center;
}

.textareArea textarea.MuiInputBase-input {
  padding: 0px !important;
}

.MuiTypography-body2.description {
  font-family: "General Sans Medium";
  font-size: 16px;
  line-height: 22px;
  color: #8d8b8b;
  padding: 0 5rem;
}

.accordian_title {
  font-family: General Sans Semibold !important;
  color: #000;
  font-size: 16px;
}

.claim_email_info {
  font-family: "General Sans Medium";
  font-size: 14px;
  color: #8d8b8b;
}

.onboarding .MuiTypography-h1 {
  font-family: "Codec Pro Bold";
  font-size: 61px;
  line-height: 69px;
  color: #1a1616;
}
.onboarding .MuiTypography-body1 {
  font-family: "General Sans Medium";
  font-size: 32px;
  color: #504d4d;
}
.onboarding .MuiButton-containedPrimary {
  background: linear-gradient(90deg, #ed1f23 0.12%, #ed1f23 8.11%, #ed1f23 15.51%, #ed2023 22.41%, #ee2023 28.89%, #ee2123 35.05%, #ef2223 40.99%, #ef2323 46.78%, #f02423 52.53%, #f12523 58.33%, #f12723 64.27%, #f22823 70.43%, #f32a23 76.91%, #f42c23 83.81%, #f52d23 91.21%, #f62f23 99.2%);
  font-family: "General Sans Bold";
  color: #ffffff;
  font-size: 26.6233px;
  border-radius: 10.6493px;
  box-shadow: 0px 12px 41px -1px rgba(237, 31, 35, 0.14);
}
.onboarding .MuiButton-outlinedSecondary {
  margin-top: 1rem;
  border: 1px solid #1a1616;
  border-radius: 8px;
  width: 336px;
  font-family: General Sans Semibold !important;
  color: #1a1616;
  font-size: 16px;
}

.contactus_cta .MuiTypography-body1 {
  font-family: "General Sans Medium";
  font-size: 32px;
  color: #504d4d;
}
.contactus_cta .MuiButton-outlinedSecondary {
  margin-top: 1rem;
  border: 1px solid #1a1616;
  border-radius: 8px;
  width: 336px;
  font-family: General Sans Semibold !important;
  color: #1a1616;
  font-size: 16px;
}

@media screen and (max-width: 1279px) {
  .popup-modal {
    position: absolute !important;
    left: 10rem;
  }
  .claim-modal {
    position: absolute !important;
    left: 10rem;
  }
}
.slick-slider {
  overflow: hidden;
}

.poll-reward-modal .linear-progress-bar {
  position: relative;
  margin: 0.5rem 0;
}
.poll-reward-modal .linear-progress-bar .choices-result {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.poll-reward-modal .linear-progress-bar .choice-text {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  left: 0;
  text-align: center;
  padding: 0 0.8rem;
}
.poll-reward-modal .linear-progress-bar .MuiLinearProgress-root {
  border: 1px solid #1a1616;
}
.poll-reward-modal .linear-progress-bar .MuiLinearProgress-root .MuiLinearProgress-bar {
  cursor: pointer !important;
}
.poll-reward-modal .linear-progress-bar.selected .MuiLinearProgress-root {
  cursor: pointer !important;
  border: 1px solid #1a1616;
}
.poll-reward-modal .linear-progress-bar.result-given .MuiLinearProgress-root {
  cursor: default !important;
  border: 1px solid #1a1616;
}
.poll-reward-modal .linear-progress-bar.result-given .MuiLinearProgress-root .MuiLinearProgress-bar {
  cursor: default !important;
}
.poll-reward-modal .linear-progress-bar.result-given .choices-result {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  cursor: default !important;
}
.poll-reward-modal .linear-progress-bar.result-given.selected .MuiLinearProgress-root {
  cursor: pointer !important;
  border: 1px solid #1a1616;
}
.poll-reward-modal .linear-progress-bar.result-given.selected .MuiLinearProgress-root .MuiLinearProgress-bar {
  cursor: pointer !important;
}
.poll-reward-modal .option-area {
  padding: 1rem;
  font-family: "Inter", sans-serif;
  position: relative;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  background: rgba(26, 117, 255, 0.1);
  border: 1px solid #1a1616;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  cursor: pointer;
  overflow: hidden;
}
.poll-reward-modal .option-area span {
  z-index: 20;
}
.poll-reward-modal .option-area.selected {
  background: rgba(26, 117, 255, 0.4);
}
.poll-reward-modal .confirm-btn {
  margin-top: 3rem;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 15px;
}
.poll-reward-modal .merch-category {
  font-family: General Sans Semibold !important;
  font-size: 13px;
  color: #1a1616;
  margin-left: 5px;
}
.poll-reward-modal .close-btn {
  color: #1a1616;
  font-family: "General Sans Bold";
  font-size: 1rem;
  border: 1px solid #504d4d;
  padding: 0.6rem 2rem;
}

.domainCname .MuiTypography-body2 {
  font-family: General Sans Semibold !important;
  font-size: 16px;
  color: #000000;
}

.deleteResetModal .MuiTypography-h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 130.9%;
}
.deleteResetModal .MuiTypography-h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130.9%;
  color: rgba(34, 51, 84, 0.5);
}

.step3RewardDetail .drop-category {
  display: inline-flex;
  border-radius: 30px;
  background: rgba(158, 205, 237, 0.15);
  padding: 8px 10px;
}
.step3RewardDetail .drop-category .MuiTypography-body1 {
  font-family: General Sans Semibold !important;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
}
.step3RewardDetail .title {
  margin-top: 20px;
  font-family: "General Sans Bold";
  font-size: 32px;
  color: #1a1616;
}
.step3RewardDetail .description {
  margin-top: 20px;
  color: #1a1616;
  font-family: "General Sans Medium";
  font-size: 13px;
}
.step3RewardDetail .statusbox .heading {
  color: #000;
  font-family: "General Sans Bold";
  font-size: 16px;
}
.step3RewardDetail .statusbox .non-heading {
  color: #000;
  font-family: "General Sans Medium";
  font-size: 16px;
}
.step3RewardDetail .collectnTitle {
  color: #1a1616;
  font-family: "General Sans Bold";
  font-size: 16px;
}
.step3RewardDetail .collectnIndvTitle {
  color: #1a1616;
  font-family: General Sans Semibold !important;
  font-size: 13px;
  margin-left: 8px;
}
.step3RewardDetail .impDt .MuiTypography-body1 {
  color: #1a1616;
  font-family: "General Sans Medium";
  font-size: 13px;
}
.step3RewardDetail .impDt .MuiTypography-h6 {
  color: #1a1616;
  font-family: "General Sans Bold";
  font-size: 20px;
}
.step3RewardDetail .taskTitle {
  color: #1a1616;
  font-family: General Sans Semibold !important;
  font-size: 25px;
}
.step3RewardDetail .tasksBox .head.MuiTypography-body1 {
  color: #1a1616;
  font-family: General Sans Semibold !important;
  font-size: 13px;
  letter-spacing: 0.26px;
}
.step3RewardDetail .tasksBox .subhead.MuiTypography-body1 {
  color: #aeacac;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  letter-spacing: 0.26px;
}
.step3RewardDetail .tasksBox .subhead.MuiTypography-body1 .MuiTypography-body1 {
  font-family: Inter;
  font-size: 10px;
}

.audience .no-membership {
  box-shadow: 0px 2px 2px 0px rgba(159, 162, 191, 0.32), 0px 9px 16px 0px rgba(159, 162, 191, 0.18);
  background: linear-gradient(180deg, rgba(12, 139, 244, 0.3) 0%, rgba(125, 250, 212, 0.3) 50.84%, rgba(235, 246, 255, 0.3) 100%), #fff;
  border: 3px solid #000 !important;
}
.audience .no-membership .MuiTypography-h2 {
  color: #000;
  font-family: "General Sans Bold";
  font-size: 17.924px;
}
.audience .no-membership .MuiTypography-body2 {
  color: #000;
  font-family: "General Sans Medium";
  font-size: 14.339px;
}
.audience .no-membership .MuiButton-containedInfo {
  margin-top: 8.96px;
  border-radius: 6px;
  background: var(--gradient-interface-cta-gradient, linear-gradient(264deg, #2a94ec 0%, #2994ec 8.07%, #2993ec 15.54%, #2893ec 22.5%, #2792ed 29.04%, #2690ed 35.26%, #248fed 41.25%, #228dee 47.1%, #208bee 52.9%, #1e88ef 58.75%, #1b86f0 64.74%, #1983f1 70.96%, #1680f1 77.5%, #137df2 84.46%, #0f7af3 91.93%, #0c77f4 100%));
}
.audience .MuiCard-root {
  cursor: pointer;
  border: 1px solid #504d4d;
  border-radius: 6px;
  box-shadow: 0px 2px 2px 0px rgba(159, 162, 191, 0.32), 0px 9px 16px 0px rgba(159, 162, 191, 0.18);
}
.audience .MuiCard-root.selected {
  border: 3px solid #ed1f23;
}
.audience .MuiTypography-h2 {
  color: #000;
  font-family: "General Sans Semibold" !important;
  font-size: 13px;
  letter-spacing: 0.26px;
}
.audience .MuiTypography-body2 {
  color: #aeacac;
  font-family: "General Sans Medium";
  font-size: 13px;
}

.MuiTypography-body2 a {
  color: #ed1f23 !important;
}

.charTxt {
  height: 21.5px !important;
}

.demoBanner {
  background-color: #1a1616;
  text-align: center;
  padding: 20px 0;
}
.demoBanner .MuiTypography-body1 {
  color: #faa21d;
  font-family: "General Sans Medium";
  font-size: 16px;
}
.demoBanner .MuiTypography-body1 strong {
  font-family: General Sans Semibold !important;
}
.demoBanner .MuiTypography-body1 .MuiLink-root {
  color: #faa21d;
  font-family: "General Sans Medium";
  font-size: 16px;
  text-decoration: underline;
}

.MuiBreadcrumbs-ol .MuiBreadcrumbs-li .MuiTypography-root {
  font-family: "General Sans";
  font-size: 13px;
}
.MuiBreadcrumbs-ol .MuiBreadcrumbs-li:last-child .MuiTypography-root {
  font-family: General Sans Semibold !important;
  font-size: 13px;
}

.selectedSocialActivity .MuiTypography-h2 {
  font-family: "General Sans Bold";
  font-size: 20px;
}
.selectedSocialActivity .activity-chip-box {
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.selectedSocialActivity .activity-chip-box .MuiChip-root {
  border-radius: 1000px;
  border: 1px solid var(--black-black-01, #1a1616);
  padding: 10px 7px;
}
.selectedSocialActivity .activity-chip-box .MuiChip-root .MuiChip-label {
  padding: 10px 0;
}
.selectedSocialActivity .activity-chip-box .MuiChip-root .MuiTypography-body1 {
  font-family: "General Sans Medium";
  font-size: 13px;
}
.selectedSocialActivity .activity-chip-box:last-child {
  margin-right: 0rem;
}

.targetCollectionSelection .MuiSelect-select .MuiBox-root {
  font-size: 20px;
  font-family: "General Sans Medium";
  color: #1a1616;
}
.targetCollectionSelection .MuiSelect-select .collection-image {
  height: 30px;
  width: 30px;
  border-radius: 6px;
  margin-right: 10px;
}

.selectedTraits .MuiTypography-h2 {
  font-family: "General Sans Bold";
  font-size: 20px;
}

.targetingCollectionAcc .MuiAccordion-root:last-child {
  border-bottom: 1px solid #000 !important;
}

footer span {
  font-family: "General Sans";
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65) !important;
}
footer span .MuiLink-root {
  font-family: "General Sans";
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65) !important;
}

.ck-editor__editable_inline {
  min-height: 120px;
}

.membership-pill .sponsor-name {
  font-family: General Sans Semibold !important;
  font-size: 13px;
  padding-left: 6px;
  color: #000;
}

.MuiTypography-h2.no-membership-title {
  font-family: "General Sans" !important;
  font-size: 25px;
}

.two-line-text {
  font-size: 1rem;
  line-clamp: 2 !important;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #000;
  word-break: break-word;
  font-family: General Sans bold;
  text-align: center;
}

.sidebar_focused {
  position: relative;
}
.sidebar_focused .basic_manu {
  visibility: hidden;
}
.sidebar_focused:hover .basic_manu {
  visibility: visible;
}

.navbar_dropdown ul {
  background: white;
  border-radius: 8px !important;
  padding: 0px !important;
  box-shadow: none !important;
  width: "134px" !important;
}
.navbar_dropdown ul div {
  border-radius: 8px !important;
  background: #f3f3f3;
  padding: 15px 10px !important;
}
.navbar_dropdown ul div li {
  box-shadow: 0px !important;
  background: "#F3F3F3" !important;
}
.navbar_dropdown ul div li:hover {
  background: "#F3F3F3" !important;
}

.navbar_dropdown_list .MuiButtonBase-root {
  color: #1A1616 !important;
}
.navbar_dropdown_list .cate_delete_modal {
  padding: 0px 0px !important;
  font-size: 14px;
  color: #1A1616;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  width: 100%;
}
.navbar_dropdown_list .cate_delete_modal .MuiBox-root {
  padding: 0px !important;
}
.navbar_dropdown_list .cate_delete_modal .MuiBox-root button {
  text-align: left !important;
  display: block !important;
  height: 16px !important;
  width: full;
}
.navbar_dropdown_list .cate_delete_modal .MuiBox-root button:hover {
  background: none !important;
}
.navbar_dropdown_list .MuiPaper-root {
  width: 134px !important;
  border: 1px solid #1A1616;
  border-radius: 8px;
  background: #F3F3F3;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.navbar_dropdown_list .MuiPaper-root ul li:hover {
  background: #f3f3f3 !important;
}

.user_box_dropdown {
  bottom: 0px;
}

.sidebar_focused:focus {
  background: #f3f3f3 !important;
}
.sidebar_focused:active {
  background: #f3f3f3 !important;
}/*# sourceMappingURL=style.css.map */